import React,{Fragment} from 'react';
import {withRouter} from 'react-router'
import loadable from "@loadable/component";


const Wrapper = ({children,location, history}) => {
    return (
        <Fragment>
            <main>
                {children}
            </main>
        </Fragment>
    );
};

export default withRouter(Wrapper);
